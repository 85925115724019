<template>
  <b-col style="padding-top: 50px;" data-anima="bottom" cols="12" sm="12" md="12" lg="7">
    <div class="step-alreadysellonline-container">
      <div class="mainobjective-title-container">
        <span class="alreadysellonline-title"> {{ $t("onboarding.already_sell_online.introduction") }} </span>
        <span class="alreadysellonline-title-count-step" style="word-wrap: nowrap;"> 02 {{ $t("onboarding.of") }} 04</span>
      </div>
      <span @click="nextStep(option.value)" :key="option.text" v-for="option in options" class="alreadysellonline-item">
        <svg style="margin-left: 25px; margin-right: 17px; min-width: 16px; min-height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7" stroke="" stroke-opacity="0.5" stroke-width="2"/>
        </svg>

        {{ option.text }}
      </span>
      <div @click="prevStep" class="alreadysellonline-btn">
        <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t("onboarding.previous") }}
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'AlreadySellOnline',
  data() {
    return {
      options: [
        {
          value: 'zero',
          text: this.$t('onboarding.already_sell_online.opt_1'),
        },
        {
          value: '1-a-10',
          text: this.$t('onboarding.already_sell_online.opt_2')
        },
        {
          value: '11-a-50',
          text: this.$t('onboarding.already_sell_online.opt_3'),
        },
        {
          value: '51-100',
          text: this.$t('onboarding.already_sell_online.opt_4'),
        },
        {
          value: '101-300',
          text: this.$t('onboarding.already_sell_online.opt_5'),
        },
        {
          value: 'nao-informado',
          text: this.$t('onboarding.already_sell_online.opt_6'),
        }
      ]
    }
  },
  methods: {
    resetAnimation () {
      const alreadysellonline = document.querySelector('.step-alreadysellonline-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 0,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return alreadysellonline.animate(keyframes, options);
    },
    nextStep(optionSelected) {
      const alreadysellonline = document.querySelector('.step-alreadysellonline-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = alreadysellonline.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('update-step', { 
          currentStep: 'alreadysellonline', 
          newStep: 'mainsocialnetwork',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_social"
            },
            {
              meta_key: "onboarding_social",
              meta_value: "done"
            },
            {
              meta_key: "SELLER_TYPE",
              meta_value: optionSelected
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const alreadysellonline = document.querySelector('.step-alreadysellonline-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = alreadysellonline.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('prev-step', { 
          currentStep: 'alreadysellonline', 
          newStep: 'mainobjetive',
        });
        this.resetAnimation();
      }
    }
  }
}
</script>

<style lang="scss">
.step-alreadysellonline-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; 
  padding-top: 30px;
  padding-right: 30px;
  height: 100%;
  max-height: 90vh;
  overflow: auto;
}
.alreadysellonline-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
.alreadysellonline-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  svg {
    stroke: #81858E;
  }
}

.alreadysellonline-item:hover {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background: rgba(229, 244, 243, 1);
  color: rgba(0, 148, 136, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  svg {
    stroke: rgba(0, 148, 136, 0.5) !important;
  }
}
.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.alreadysellonline-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 15px;
  color: rgba(154, 154, 154, 1);
}
.alreadysellonline-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  min-height: 55px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>