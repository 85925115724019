<template>
  <b-col style="padding-top: 100px;" data-anima="bottom" class="pr-2" cols="12" sm="12" md="12" lg="7">
    <div class="step-bankaccounttype-container">
      <div class="mainobjective-title-container">
        <span class="bankaccounttype-title"> <span class="bankaccounttype-title">{{ $t("onboarding.bank_account_type.text_1") }}</span></span>
        <span class="bankaccounttype-title-count-step" style="word-wrap: nowrap;"> 04 {{ $t("onboarding.of") }} 04</span>
      </div>

      <span @click="selectAccountType(option.value)" :key="option.text" v-for="option in options" class="alreadysellonline-item">
        <template v-if="account_type === option.value">
          <svg style="margin-left: 25px; margin-right: 17px; min-width: 16px; min-height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="#81858E" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" stroke="" stroke-opacity="0.5" stroke-width="2"/>
          </svg>
        </template>
        <template v-else>
          <svg style="margin-left: 25px; margin-right: 17px; min-width: 16px; min-height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7" stroke="" stroke-opacity="0.5" stroke-width="2"/>
          </svg>
        </template>

        {{ option.text }}
      </span>

      <template v-if="showEnterpriseForm">
        <!-- Company Name -->
        <!-- <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="bankaccounttype-title">{{ $t('views.seller.my_account.text_1586') }}*</span>
          <span class="bankaccounttype-title-count-step"></span>
        </div>
        <b-form-group label="" label-for="name_company">
          <b-form-input
            class="mainsocialnetwork-item"
            id="name_company"
            name="name_company"
            v-model="company.name"
            type="text"
            :placeholder="$t('views.seller.my_account.text_1177')"
            v-validate="'required'"
          ></b-form-input>
          <b-form-invalid-feedback :state="errors.items.length && errors.items.some(x => x.field === 'name_company') ? false : true">
            {{ $t('views.seller.my_account.text_1178') }}
          </b-form-invalid-feedback>
        </b-form-group> -->
        <!-- CNPJ -->
        <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="bankaccounttype-title">{{ $t('views.seller.my_account.text_1181') }}*</span>
          <span class="bankaccounttype-title-count-step"></span>
        </div>
        <b-form-group label="" label-for="cnpj" id="campo-cnpj">
          <b-form-input
            class="mainsocialnetwork-item"
            id="cnpj"
            name="cnpj"
            v-model="company.cnpj"
            :placeholder="$t('views.seller.my_account.text_1182')"
            v-mask="['##.###.###/####-##']"
            v-validate="'required'"
            @input="updateCnpjIsValid"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="
              (errors.items.length && errors.items.some(x => x.field === 'cnpj')) || !cpnjIsValid ? false : true
            "
          >
            {{
              !errors.items.some(x => x.field === 'cnpj') && !cpnjIsValid
                ? $t('views.seller.my_account.text_1587_1')
                : $t('views.seller.my_account.text_1587')
            }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- Corporate name -->
        <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="bankaccounttype-title">{{ $t('views.seller.my_account.text_1586') }}</span>
          <span class="bankaccounttype-title-count-step"></span>
        </div>
        <b-form-group label="" label-for="name">
          <b-form-input
            class="mainsocialnetwork-item"
            id="name"
            name="name"
            v-model="company.name"
            type="text"
            :placeholder="$t('views.seller.my_account.text_1177')"
            v-validate="'required'"
          ></b-form-input>
          <b-form-invalid-feedback :state="errors.items.length && errors.items.some(x => x.field === 'name') ? false : true">
            {{ $t('views.seller.my_account.text_1178') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- Social Contract -->
        <!-- <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="bankaccounttype-title">{{ $t('views.seller.my_account.text_1184') }}*</span>
          <span class="bankaccounttype-title-count-step"></span>
        </div>
        <b-form-group label="" label-for="contract">
          <b-form-file
            class="mainsocialnetwork-item"
            id="contract"
            name="contract"
            plain
            accept=".pdf"
            v-model="contract_user"
            v-validate="'required'"
            @change="changeContract = true"
          ></b-form-file>

          <b-form-invalid-feedback :state="errors.items.length && errors.items.some(x => x.field === 'contract') ? false : true">
            {{ $t('views.seller.my_account.text_1185') }}
          </b-form-invalid-feedback>
          <p class="info-documento">
            {{ $t('views.seller.my_account.text_1186') }}
          </p>
        </b-form-group> -->
        <!-- Telephone -->
        <!-- <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="bankaccounttype-title">{{ $t('views.seller.my_account.text_1570') }}</span>
          <span class="bankaccounttype-title-count-step"></span>
        </div>
        <b-form-group label="" label-for="phone_company">
          <VuePhoneNumberInput
            v-model="whatsappEmpresa"
            :translations="$i18n.locale === 'pt' ? translations_pt : (
              $i18n.locale === 'es' ? translations_es : translations_en
            )"
            error-color="#ff0c37"
            size="lg"
            :default-country-code="'BR'"
            :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
            ref="telinput"
            :required="true"
            :countries-height="50"
            :border-radius="5"
            @update="onUpdateEmpresa"
          />
        </b-form-group> -->
      </template>

      <!-- Footer -->
      <div class="d-flex" style="justify-content: space-between;">
        <!-- Prev -->
        <div @click="prevStep" class="bankaccounttype-btn">
          <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('onboarding.previous') }}
        </div>
        <!-- Next -->
        <div v-if="account_type === 'PF'" @click="createOrUpdateAccountType" class="bankaccounttype-btn-next">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-if="account_type === 'PJ'" @click="validateEnterpriseForm" class="bankaccounttype-btn-next">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-if="account_type === null" class="bankaccounttype-btn-next-disabled">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

    </div>
  </b-col>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import validateCNPJ from '@/utils/validateCNPJ.js'

import UserSettingsService from '@/services/resources/UserSettingsService'
import UserService from '@/services/resources/UserService'

const serviceUserSettings = UserSettingsService.build()
const serviceUser = UserService.build()

export default {
  name: 'BankAccountTypeStep',
  components: { VuePhoneNumberInput },
  data() {
    return {
      is_editable: true,
      account_type: null,
      showEnterpriseForm: false,
      translations_pt: {
        countrySelectorLabel: 'Código do país',
        countrySelectorError: 'Escolha um país',
        phoneNumberLabel: 'Número de Telefone',
        example: 'Exemplo: ',
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      // whatsappEmpresa: '',
      company: {
        document_type: 'CNPJ',
        documents: [{ status: 'REQUESTED' }],
        name: '',
        fantasy_name: '',
        // telephone: '',
        // country_phone: '',
        // support_telephone: '',
        // support_country_phone: '',
        
        email: '',
        cnpj: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        country: 'BR',
      },
      contract_user: null,
      changeContract: false,
      cpnjIsValid: true,
      ignore_phone_validation: true
    }
  },
  computed: {
    options() {
      return [
        {
          text: this.$t('views.seller.settings.text_1393'),
          value: 'PF'
        },
        {
          text: this.$t('views.seller.settings.text_1394'),
          value: 'PJ'
        }
      ]
    }
  },
  methods: {
    async validateEnterpriseForm() {
      this.cpnjIsValid = validateCNPJ(this.company.cnpj)
      this.$validator.validateAll().then(async (result) => {
        if (result && this.cpnjIsValid) {
          await this.updateCompany()
          this.createOrUpdateAccountType()
        }
      })
    },
    selectAccountType(value) {
      if (!this.is_editable) return
      this.account_type = value
      if(this.account_type === 'PJ') {
        this.showEnterpriseForm = true
      }else{
        this.showEnterpriseForm = false
      }
    },
    createOrUpdateAccountType() {
      serviceUserSettings.create({type: this.account_type}, '/account-type')
        .then(() => this.nextStep())
        .catch(err => console.error(err))
    },
    async updateCompany() {
      let data = {company: this.company}

      // if(!this.whatsappEmpresa || this.whatsappEmpresa === '') {
      //   data.company.telephone = null
      //   data.company.country_phone = null
      // }

      if (this.changeContract) {
        data.company.documents = [this.contract_user]
      } else {
        data.company.documents = []
      }

      data._method = 'PUT'
      data = this.jsonToFormData({...data, ignore_phone_validation: true})
      await serviceUser.create(data, this.$store.getters.getProfile.id || JSON.parse(localStorage.user).id)
        .then(() => { this.cpnjIsValid = true })
        .catch(err => console.error(err))
    },
    resetAnimation () {
      const bankaccounttype = document.querySelector('.step-bankaccounttype-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 5000,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return bankaccounttype.animate(keyframes, options);
    },
    nextStep(optionSelected) {
      const bankaccounttype = document.querySelector('.step-bankaccounttype-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = bankaccounttype.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('update-step', { 
          currentStep: 'bankaccounttype', 
          newStep: 'done',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_done"
            },
            {
              meta_key: "onboarding_account_type",
              meta_value: "done"
            },
            {
              meta_key: "account_type",
              meta_value: this.account_type
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const bankaccounttype = document.querySelector('.step-bankaccounttype-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = bankaccounttype.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('prev-step', { 
          currentStep: 'bankaccounttype', 
          newStep: 'mainsocialnetwork',
        });
        this.resetAnimation();
      }
    },
    onUpdateEmpresa(payload) {
      if(payload.phoneNumber) {
        this.company.telephone = `+${payload.countryCallingCode+payload.phoneNumber.replace(' ', '').replace('-','').replace('(','').replace(')','')}`
        this.company.country_phone = payload.countryCode
      }
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      const formData = new FormData()
      this.buildFormData(formData, data)
      return formData
    },
    updateCnpjIsValid(cnpj) {
      this.cpnjIsValid = validateCNPJ(cnpj)
    }
  },
  async created() {
    this.userLogged = await this.$store.dispatch('userRequest')
    if (this.userLogged.account_type) {
      this.account_type = this.userLogged.account_type;
      if(this.account_type === 'PENDING_PJ'){
        this.account_type = 'PJ'
      }
      this.is_editable = false;
      if(this.account_type === 'PJ') {
        this.showEnterpriseForm = true
      }
    }
  }
}
</script>

<style lang="scss">
.step-bankaccounttype-container {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}
.bankaccounttype-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.alreadysellonline-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  svg {
    stroke: #81858E;
  }
}

.alreadysellonline-item:hover {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background: rgba(229, 244, 243, 1);
  color: rgba(0, 148, 136, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  svg {
    stroke: rgba(0, 148, 136, 0.5) !important;
  }
}

.mainsocialnetwork-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px !important;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  border: none !important;
  margin-top: 15px;
}

.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bankaccounttype-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(154, 154, 154, 1);
}
.bankaccounttype-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
  margin-bottom: 50px;
}
.bankaccounttype-btn-next {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #009488;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.bankaccounttype-btn-next-disabled {
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #c4c4c4;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}

.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>