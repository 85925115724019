<template>
  <b-col style="padding-top: 100px;" data-anima="bottom" cols="12" sm="12" md="12" lg="7">
    <div class="step-mainsocialnetwork-container">
      <div class="mainobjective-title-container">
        <span class="mainsocialnetwork-title"> {{ $t("onboarding.introduction_social_step") }}</span>
        <span class="mainsocialnetwork-title-count-step" style="word-wrap: nowrap;"> 03 {{ $t("onboarding.of") }} 04</span>
      </div>
      <SocialSelect
        v-model="socialNetwork"
        :options="items"
      />
      <div style="margin-top: 40px;" class="mainobjective-title-container">
        <span class="mainsocialnetwork-title"> {{ $t("onboarding.what_is_your_user_on") }} {{ socialNetwork.text }}?</span>
        <span class="mainsocialnetwork-title-count-step"></span>
      </div>
      <input style="padding-left: 30px;" v-model="userSocialNetwork" class="mainsocialnetwork-item" type="text" :placeholder="$t('username')" />
      <div class="d-flex" style="justify-content: space-between;">
        <div @click="prevStep" class="mainsocialnetwork-btn">
          <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('onboarding.previous') }}
        </div>
        <div v-if="userSocialNetwork.length > 0" @click="nextStep" class="mainsocialnetwork-btn-next">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-else class="mainsocialnetwork-btn-next-disabled">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import SocialSelect from './SocialSelect.vue'
export default {
  name: 'MainSocialNetwork',
  components: {
    SocialSelect
  },
  data() {
    return {
      socialNetwork: {
        text: 'Instagram',
        value: 'instagram'
      },
      userSocialNetwork: "",
      items: [
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Instagram',
          value: 'instagram'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Facebook',
          value: 'facebook'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Youtube',
          value: 'youtube'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'TikTok',
          value: 'tiktok'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Twitter',
          value: 'twitter'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Linkedin',
          value: 'linkedin'
        },
        {
          icon: '../..  /assets/img/instagram_social.svg',
          text: 'Pinterest',
          value: 'pinterest'
        },
      ]
    }
  },
  methods: {
    resetAnimation () {
      const mainsocialnetwork = document.querySelector('.step-mainsocialnetwork-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 5000,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return mainsocialnetwork.animate(keyframes, options);
    },
    nextStep(optionSelected) {
      const mainsocialnetwork = document.querySelector('.step-mainsocialnetwork-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = mainsocialnetwork.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('update-step', { 
          currentStep: 'mainsocialnetwork', 
          newStep: 'bankaccounttype',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_account_type"
            },
            {
              meta_key: "onboarding_social",
              meta_value: "done"
            },
            {
              meta_key: "social_network_type",
              meta_value: this.socialNetwork.value
            },
            {
              meta_key: "social_network_username",
              meta_value: this.userSocialNetwork
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const mainsocialnetwork = document.querySelector('.step-mainsocialnetwork-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = mainsocialnetwork.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('prev-step', { 
          currentStep: 'mainsocialnetwork', 
          newStep: 'alreadysellonline',
        });
        this.resetAnimation();
      }
    }
  }
}
</script>

<style lang="scss">
.step-mainsocialnetwork-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}
.mainsocialnetwork-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.mainsocialnetwork-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px !important;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  border: none !important;
  margin-top: 15px;
}

.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainsocialnetwork-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(154, 154, 154, 1);
}
.mainsocialnetwork-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
  margin-bottom: 50px;
}
.mainsocialnetwork-btn-next {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #009488;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.mainsocialnetwork-btn-next-disabled {
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #c4c4c4;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.mainsocialnetwork-select {
  display: flex;
  max-height: 70px;
  min-height: 70px;
  align-items: center;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
}
#social-dropdown button:focus {
  outline: none !important;
  border: none;
}
#social-dropdown {
  outline: none;
  border: none;
  width: 100%;
}
#social-dropdown__BV_toggle_ {
  outline: none !important;
  border: none !important;
}
.dropdown-item-social {
  display: flex;
  min-width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 6px;
  padding-right: 9px;
}
</style>